<section class="tf-section tf-roadmap">
    <div class="overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="tf-title m-b30"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <p class="h8 sub-title">ROADMAP</p>
                    <h4 class="title">Compact Games Milestones</h4>
                </div>
            </div>
            <div class="col-md-12">
                <div class="roadmap desktop-only">
                    <div class="box box-left">
                        <ng-container *ngFor="let item of roadmapItems; let even = even">
                            <div *ngIf="even"
                                [ngClass]="{'style' : !item.isDone}"
                                class="rm-box"
                                data-aos="fade-right"
                                data-aos-duration="1200">
                                <div class="corner-box">
                                    <h5 [ngClass]="{'question-marks' : item.questionMarks}">{{item.questionMarks ? '???'
                                        : item.title}}</h5>
                                    <p *ngIf="!item.questionMarks">{{item.description}}</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="box box-right">
                        <ng-container *ngFor="let item of roadmapItems; let odd = odd">
                            <div *ngIf="odd"
                                [ngClass]="{'style' : !item.isDone}"
                                class="rm-box"
                                data-aos="fade-left"
                                data-aos-duration="1200">
                                <div class="corner-box">
                                    <h5 [ngClass]="{'question-marks' : item.questionMarks}">{{item.questionMarks ? '???'
                                        : item.title}}</h5>
                                    <p *ngIf="!item.questionMarks">{{item.description}}</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>


                <div class="roadmap mobile-only">
                    <div class="box box-left">
                        <ng-container *ngFor="let item of roadmapItems">
                            <div [ngClass]="{'style' : !item.isDone}"
                                class="rm-box"
                                data-aos="fade-right"
                                data-aos-duration="1200">
                                <div class="corner-box">
                                    <h5 [ngClass]="{'question-marks' : item.questionMarks}">{{item.questionMarks ? '???'
                                        : item.title}}</h5>
                                    <p *ngIf="!item.questionMarks">{{item.description}}</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>