import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = request.headers.append("x-functions-key", "epaBXR44XLXLO9c3dyZwmLTdVi_7P1nGLtILO9M1Sy0JAzFuCZZVzA==");
        let updatedRequest = request.clone({ headers: headers });
        return next.handle(updatedRequest);
    }
}
