import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ToastrModule } from "ngx-toastr";
import { SwiperModule } from "swiper/angular";
import { AppRoutingModule } from "./app-routing.module";
import { GlobalErrorHandler } from "./common/global-error-handler";
import { AppComponent } from "./components/app/app.component";
import { ButtonComponent } from "./components/common/button/button.component";
import { NumberInputComponent } from "./components/common/number-input/number-input.component";
import { BlankMessagePageComponent } from "./components/miscellaneous/blank-message-page/blank-message-page.component";
import { ComingSoonComponent } from "./components/miscellaneous/coming-soon/coming-soon.component";
import { SubscriptionCancellationComponent } from "./components/miscellaneous/subscription/subscription-cancellation/subscription-cancellation.component";
import { SubscriptionConfirmationComponent } from "./components/miscellaneous/subscription/subscription-confirmation/subscription-confirmation.component";
import { CollaborationComponent } from "./components/website/contact/collaboration/collaboration.component";
import { ContactUsComponent } from "./components/website/contact/contact-us/contact-us.component";
import { ContactComponent } from "./components/website/contact/contact.component";
import { JoinTeamComponent } from "./components/website/contact/join-team/join-team.component";
import { FooterComponent } from "./components/website/footer/footer.component";
import { HeaderComponent } from "./components/website/header/header.component";
import { AboutComponent } from "./components/website/landing-page/about/about.component";
import { FaqComponent } from "./components/website/landing-page/faq/faq.component";
import { HeroComponent } from "./components/website/landing-page/hero/hero.component";
import { HowWeWorkComponent } from "./components/website/landing-page/how-we-work/how-we-work.component";
import { LandingPageComponent } from "./components/website/landing-page/landing-page/landing-page.component";
import { GamesSneakPeeksComponent } from "./components/website/landing-page/latest-news/games-sneak-peeks.component";
import { NftsShowcaseComponent } from "./components/website/landing-page/nfts-showcase/nfts-showcase.component";
import { PartnersComponent } from "./components/website/landing-page/partners/partners.component";
import { RoadmapComponent } from "./components/website/landing-page/roadmap/roadmap.component";
import { TeamComponent } from "./components/website/landing-page/team/team.component";
import { MintPageComponent } from "./components/website/mint-page/mint-page.component";
import { MintComponent } from "./components/website/mint-page/mint/mint.component";
import { WhitelistCheckComponent } from "./components/website/mint-page/whitelist-check/whitelist-check.component";
import { NetworkHandlerComponent } from "./components/website/network-handler/network-handler.component";
import { VirtualConsoleComponent } from "./components/website/virtual-console/virtual-console/virtual-console.component";
import { WebsiteComponent } from "./components/website/website/website.component";
import { HeadersInterceptor } from "./interceptors/headers.interceptor";
import { ContactService } from "./services/contact.service";
import { SubscriptionService } from "./services/subscription.service";
import { Web3Service } from "./services/web3.service";
import { WhitelistService } from "./services/whitelist.service";
import { VideoComponent } from "./components/common/video/video.component";
import { NetworkService } from "./services/network.service";
import { Web3OnboardService } from "./services/web3-onboard.service";
import { LoggingService } from "./services/logging.service";
declare global {
  interface Window {
    ethereum: any;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ComingSoonComponent,
    HeaderComponent,
    AboutComponent,
    NftsShowcaseComponent,
    RoadmapComponent,
    TeamComponent,
    PartnersComponent,
    FaqComponent,
    FooterComponent,
    HeroComponent,
    GamesSneakPeeksComponent,
    HowWeWorkComponent,
    WebsiteComponent,
    SubscriptionConfirmationComponent,
    SubscriptionCancellationComponent,
    BlankMessagePageComponent,
    ContactComponent,
    LandingPageComponent,
    ContactUsComponent,
    JoinTeamComponent,
    CollaborationComponent,
    VirtualConsoleComponent,
    MintPageComponent,
    MintComponent,
    WhitelistCheckComponent,
    ButtonComponent,
    NetworkHandlerComponent,
    NumberInputComponent,
    VideoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SwiperModule,
    FontAwesomeModule,
    MatTooltipModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    AppRoutingModule
  ],
  providers: [
    ContactService,
    SubscriptionService,
    NetworkService,
    Web3OnboardService,
    Web3Service,
    WhitelistService,
    LoggingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: 'BASE_URL', useFactory: getBaseUrl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
  // return document.getElementsByTagName('base')[0].href;
  return "https://compact-games-nft.azurewebsites.net/api";
}
