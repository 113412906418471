import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import $ from "jquery";
import { Subscription } from "rxjs";
import { Web3Service } from "../../../services/web3.service";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: 'cg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  public connectedAddress: string;
  public connectTooltip: string;
  public shortenedAddress: string;
  public menuItems: Array<any> = [
    { title: "Home", url: "" },
    { title: "Virtual Console", url: "/play" },
    { title: "Contact", url: "/contact" },
    { title: "Mint", url: "/mint", isDisabled: false },
  ]

  constructor(
    private web3Service: Web3Service,
    private loggingService: LoggingService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.setupMobileNav();
    this.setupFixedHeader();
    this.subscription = this.web3Service.selectedAccount$.subscribe(address => {
      if (address === null) {
        this.loggingService.clearAuthenticatedUserContext();
      } else {
        this.loggingService.setAuthenticatedUserContext(address);
      }

      this.connectedAddress = address;
      this.connectTooltip = address ? 'Disconnect wallet' : 'Connect wallet';
      this.shortenedAddress = this.getShortenedAddress(address);
      this.changeDetectorRef.detectChanges();
    });
  }

  public async connect(): Promise<void> {
    if (this.connectedAddress) {
      await this.web3Service.logout();
    } else {
      await this.web3Service.login();
    }
  }

  public getShortenedAddress(address: string): string {
    const firstPart = address?.substring(0, 5);
    const secondPart = address?.substring(address.length - 4);
    return address ? `${firstPart}...${secondPart}` : null;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  private setupMobileNav() {
    /* Mobile nav code from template */
    var mobile = window.matchMedia("(max-width: 991px)");
    var wrapMenu = $("#site-header-inner");
    var navExtw = $(".nav-extend.active");
    var navExt = $(".nav-extend.active").children();

    responsivemenu(mobile);

    mobile.addListener(responsivemenu);

    function responsivemenu(mobile) {
      if (mobile.matches) {
        $("#main-nav")
          .attr("id", "main-nav-mobi")
          .appendTo("#header_main")
          .hide()
          .children(".menu")
          .append(navExt)
          .find("li:has(ul)")
          .children("ul")
          .removeAttr("style")
          .hide()
          .before('<span class="arrow"></span>');
      } else {
        $("#main-nav-mobi")
          .attr("id", "main-nav")
          .removeAttr("style")
          .prependTo(wrapMenu)
          .find(".ext")
          .appendTo(navExtw)
          .parent()
          .siblings("#main-nav")
          .find(".sub-menu")
          .removeAttr("style")
          .prev()
          .remove();

        $(".mobile-button").removeClass("active");
        $(".sub-menu").css({ display: "block" });
      }
    }
    $(document).on("click", ".mobile-button", () => {
      $(".mobile-button").toggleClass("active");
      $("#main-nav-mobi").slideToggle();
      $("#header_main").toggleClass("active");
    });

    $(document).on("click", "#main-nav-mobi .arrow", () => {
      $("#main-nav-mobi .arrow").toggleClass("active").next().slideToggle();
    });

    $(document).on("click", "#main-nav-mobi .menu-button", function () {
      $(".mobile-button").toggleClass("active");
      $("#main-nav-mobi").slideToggle();
      $("#header_main").toggleClass("active");
    });
  };

  private setupFixedHeader() {
    if ($('body').hasClass('header-fixed')) {
      var nav = $('#header_main');
      if (nav.length) {
        var offsetTop = nav.offset().top,
          injectSpace = $('<div />', {
          }).insertAfter(nav);
        $(window).on('load scroll', function () {
          if ($(window).scrollTop() > 200) {
            nav.addClass('is-fixed');
            injectSpace.show();
          } else {
            nav.removeClass('is-fixed');
            injectSpace.hide();
          }

          if ($(window).scrollTop() > 300) {
            nav.addClass('is-small');
          } else {
            nav.removeClass('is-small');
          }
        })
      }
    }
  }
}
