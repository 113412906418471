<div id="wrapper">
    <cg-network-handler></cg-network-handler>
    <cg-header></cg-header>
    <router-outlet></router-outlet>
    <cg-footer></cg-footer>

    <div class="modal fade popup"
      id="popup_bid"
      tabindex="-1"
      role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered"
        role="document">
        <div class="modal-content">
          <div class="header-popup">
            <h5>Connect to a wallet</h5>
            <button type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body center">
            <div class="box-wallet-inner">
              <div class="sc-box">
                <div class="img">
                  <img src="assets/images/common/icon-1.png"
                    alt="Image">
                </div>
                <h6 class="heading"><a href="login.html">Meta Mask</a> </h6>
                <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt</p>
              </div>
              <div class="sc-box">
                <div class="img">
                  <img src="assets/images/common/icon-6.png"
                    alt="Image">
                </div>
                <h6 class="heading"><a href="login.html"> Bitski</a></h6>
                <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt</p>
              </div>
              <div class="sc-box">
                <div class="img">
                  <img src="assets/images/common/vector.png"
                    alt="Image">
                </div>
                <h6 class="heading"><a href="login.html">Fortmatic</a> </h6>
                <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt</p>
              </div>
              <div class="sc-box">
                <div class="img">
                  <img src="assets/images/common/icon-2.png"
                    alt="Image">
                </div>
                <h6 class="heading"><a href="login.html">Coinbase Wallet</a> </h6>
                <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt</p>
              </div>
              <div class="sc-box">
                <div class="img">
                  <img src="assets/images/common/icon-3.png"
                    alt="Image">
                </div>
                <h6 class="heading"><a href="login.html">Authereum</a> </h6>
                <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt</p>
              </div>
              <div class="sc-box">
                <div class="img">
                  <img src="assets/images/common/icon-4.png"
                    alt="Image">
                </div>
                <h6 class="heading"><a href="login.html">Kaikas</a> </h6>
                <p class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a id="scroll-top" [class.show]="windowScrolled" (click)="scrollTop()"></a>
