import { Component } from "@angular/core";

@Component({
  selector: 'app-subscription-confirmation',
  templateUrl: './subscription-confirmation.component.html',
  styleUrls: ['./subscription-confirmation.component.scss']
})
export class SubscriptionConfirmationComponent {

  constructor() {
  }
}
