import { environment } from "src/environments/environment";
import { Network } from "../enums/network";

export class NetworkService {
    private static networks: NetworkModel[] = [
        {
            id: Network.AbstractTestnet,
            name: 'abstract testnet',
            displayName: 'Abstract Testnet',
            rpcUrls: ['https://api.testnet.abs.xyz'],
            wsAnkrRpc: `wss://rpc.ankr.com/eth/ws/${environment.ankrApiKey}`,
            nativeCurrency: { name: "ETHER", symbol: "ETH", decimals: 18 },
            blockExplorerUrls: ["https://explorer.testnet.abs.xyz/"]
        },
        {
            id: Network.Unknown,
            name: 'unknown',
            displayName: 'Unsupported',
            rpcUrls: [],
            wsAnkrRpc: ``,
            nativeCurrency: null,
            blockExplorerUrls: null
        }
    ];

    public static getNetwork(networkId: number): NetworkModel {
        return this.networks.find(x => x.id == networkId) ?? this.networks.find(network => network.id === Network.Unknown);
    }
}

export interface NetworkModel {
    id: number;
    name: string;
    displayName: string;
    rpcUrls: string[];
    wsAnkrRpc: string;
    nativeCurrency: Currency;
    blockExplorerUrls: string[];
}

export interface Currency {
    name: string;
    symbol: string;
    decimals: number;
}
