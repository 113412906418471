<div class="cg-video-container" (click)="closeVideo()">
    <iframe class="cg-video" [src]="trustedSrc" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
</div>

<!-- <div class="cg-video-container" (click)="closeVideo()">
    <video class="cg-video" controls>
        <source src="assets/videos/platform-overview.mp4" type="video/mp4">
        Your browser does not support the videos.
    </video>
</div> -->