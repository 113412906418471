<div id="wrapper">
  <section class="page-title comimg-soon">
    <div class="sub-heading"><span>Site</span><span>Under</span><span>Construction</span></div>
    <h3 data-aos="zoom-in"
      data-aos-duration="800">
      <span>Coming</span><span>Soon</span>
    </h3>

    <div class="featured-countdown">
      <span class="slogan"></span>
      <span class="js-countdown"
        data-timer="1865550"></span>
    </div>
  </section>
</div>