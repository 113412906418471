// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from "src/app/models/environment";
import { Network } from "src/app/enums/network";

export const environment: Environment = {
  production: false,
  appInsightsInstrumentationKey: null,
  network: Network.AbstractTestnet,
  compactGamesNftAddress: '0xD51b2Bd626D9c0f12026f989a0765dC8A690489d',
  ankrApiKey: 'e1b31c5a6db939f796becac63509b04218b19a8fb9be9a01454e870cfe0708bc',
  apiUrl: 'https://compact-games-nft.azurewebsites.net/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
