import { Component, OnInit } from "@angular/core";
import { SwiperOptions } from "swiper";

@Component({
  selector: 'cg-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  teamMembers = [
    { name: "Grygier", description: "Professional web 2 programmer with hands-on experience in web 3.0 and game development", avatar: "" },
    { name: "Crypto Tigerine", description: "Creative mind - passionate graphics designer and front-end developer", avatar: " " },
    { name: "Brownie Boss", description: "One of our motivators who adds a spark to our innovative initiatives", avatar: "" }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  teamSliderConfig: SwiperOptions = {
    slidesPerView: 1,
    loop: false,
    spaceBetween: 30,
    navigation: {
      // clickable: true,
      nextEl: ".button-next-team",
      prevEl: ".button-prev-team",
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      1300: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  };

}
