import { Component } from "@angular/core";
import { AppConstants } from "src/app/common/app-constants";
import { FaqItem } from "src/app/models/faq-item";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: 'cg-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

  public faq: FaqItem[] = [
    {
      question: "What is Compact Games NFT",
      answer: `Compact Games NFT is next level NFT collection featuring complete games as non-fungible tokens.
      It is one of its kind collection giving you many benefits where some of them are:<br>
      <ul class='faq-list'>
        <li>access to virtual console</li>
        <li>unique and fully featured computer game</li>
        <li>access to competitions with valuable rewards</li>
        <li>access to future collections of Compact Games</li>
        <li>access to automated tools developed by Compact Games</li>
        <li>perks on future platforms developed by Compact Games</li>
      </ul>`,
      isActive: false
    },
    {
      question: "What is the supply of Compact Games NFT collection?",
      // answer: "Total supply of Compact Games NFT collection is not set yet and will be announced when we'll get closer to the mint.",
      answer: `Total supply of Compact Games NFT collection is ${AppConstants.nftsCount} items.`,
      isActive: false
    },
    // {
    //   question: "How will the sale be conducted?",
    //   answer: `The sale will consists of 2 stages. In the first stage only allowlisted people will be able to participate.
    //   If there will be any NFTs left we'll run a public sale for the leftovers.`,
    //   isActive: false
    // },
    {
      question: "When can I buy the Compact Games NFT?",
      // answer: "Mint event is estimated to happen somewhere in 2024. For more specific date make sure to follow us on social media.",
      answer: "Mint event is estimated to happen at the launch of Abstract Mainnet network. For more specific date make sure to follow us on social media.",
      isActive: false
    },
    {
      question: "How can I buy the Compact Games NFT?",
      answer: "You can buy Compact Games NFT on our website. Instructions and exact link will be provided on our social media when it will be closer to the mint.",
      isActive: false
    },
    {
      question: "What is the price of the Compact Games NFT?",
      answer: "Price of the Compact Games NFT will be announced at a later time before the sale begins.",
      isActive: false
    }
  ]

  constructor(private loggingService: LoggingService) { }

  public logFaqClick(faqItem: FaqItem) {
    this.loggingService.logEvent("FAQ item click", { name: faqItem.question })
  }
}
