import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { JoinTeamForm } from "src/app/models/join-team-form";
import { ContactService } from "src/app/services/contact.service";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: 'cg-join-team',
  templateUrl: './join-team.component.html',
  styleUrls: ['./join-team.component.scss']
})
export class JoinTeamComponent {

  isSending = false;

  contactForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    area: new UntypedFormControl(''),
    helpInitiative: new UntypedFormControl('', [Validators.required]),
    expectations: new UntypedFormControl('', [Validators.required]),
    aboutCandidate: new UntypedFormControl('', [Validators.required]),
  });

  get firstName() { return this.contactForm.get('firstName'); }
  get email() { return this.contactForm.get('email'); }
  get area() { return this.contactForm.get('area'); }
  get helpInitiative() { return this.contactForm.get('helpInitiative'); }
  get expectations() { return this.contactForm.get('expectations'); }
  get aboutCandidate() { return this.contactForm.get('aboutCandidate'); }

  constructor(
    private contactService: ContactService,
    private toastr: ToastrService,
    private loggingService: LoggingService
  ) { }

  public onSubmit(): void {
    this.loggingService.logEvent("Submit join team form", { "valid": this.contactForm.valid, "form": this.contactForm.value })
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid)
      return;

    const form: JoinTeamForm = this.contactForm.getRawValue();
    this.isSending = true;
    this.contactService.sendJoinTeamForm(form)
      .subscribe(
        res => {
          this.contactForm.reset();
          this.toastr.success('Your message has been sent.');
          this.isSending = false;
        },
        err => {
          this.toastr.error('Something went wrong.');
          this.isSending = false;
          throw(err);
        },
        () => { this.isSending = false; }
      );
  }
}
