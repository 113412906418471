import { Component, OnInit } from "@angular/core";
import { ethers } from "ethers";
import { combineLatest, from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CompactGamesNftContractService } from "src/app/services/compact-games-nft-contract.service";
import { Web3Service } from "src/app/services/web3.service";
import { WhitelistService } from "src/app/services/whitelist.service";

@Component({
  selector: 'cg-mint',
  templateUrl: './mint.component.html',
  styleUrls: ['./mint.component.scss']
})
export class MintComponent implements OnInit {

  public mintedTokenCount$: Observable<number>;
  public maxTokenCount$: Observable<number>;

  public isMintedOut$: Observable<boolean>;

  public isPublicSaleOpen$: Observable<boolean>;
  public isWhitelistSaleOpen$: Observable<boolean>;
  public isWhitelisted$: Observable<boolean>;

  public isPresaleMintButtonEnabled$: Observable<boolean>;

  public isMinting: boolean = false;
  public selectedNftCount: number = 1;
  public nftPrice: number;

  constructor(
    private web3Service: Web3Service,
    private whitelistService: WhitelistService,
    private cgNftContractService: CompactGamesNftContractService) { }

  public ngOnInit(): void {
    this.maxTokenCount$ = from(this.cgNftContractService.getMaxTokenCount());
    this.mintedTokenCount$ = this.cgNftContractService.mintedTokenCount$;
    this.isPublicSaleOpen$ = this.cgNftContractService.publicSaleOpen$;
    this.isWhitelistSaleOpen$ = this.cgNftContractService.presaleOpen$;
    this.isWhitelisted$ = this.web3Service.selectedAccount$.pipe(map(address => this.whitelistService.isWhitelisted(address)));
    this.setIsMintedOut();
    this.setPresaleMintEnabled();

    this.cgNftContractService.getNftPrice().then(price => {
      this.nftPrice = Number(ethers.utils.formatEther(price));
    });
  }

  public playVideo(el: HTMLMediaElement) {
    el.muted = true;
    el.play();
  }

  public async publicMint(): Promise<void> {
    this.isMinting = true;
    await this.cgNftContractService.mint(this.selectedNftCount).then(
      () => this.isMinting = false,
      () => this.isMinting = false
    );
  }

  public async whitelistMint(): Promise<void> {
    const walletAddress = this.web3Service.selectedAccount$.value;
    const merkleTreeProof = this.whitelistService.getMerkleTreeProof(walletAddress);

    this.isMinting = true;
    await this.cgNftContractService.presaleMint(this.selectedNftCount, merkleTreeProof).then(
      () => this.isMinting = false,
      () => this.isMinting = false
    );
  }

  private setIsMintedOut(): void {
    this.isMintedOut$ = combineLatest([this.mintedTokenCount$, this.maxTokenCount$]).pipe(
      map(([mintedCount, maxTokenCount]) => mintedCount === maxTokenCount)
    );
  }

  private setPresaleMintEnabled(): void {
    this.isPresaleMintButtonEnabled$ = combineLatest([this.isWhitelisted$, this.isWhitelistSaleOpen$, this.isMintedOut$]).pipe(
      map(([isWhitelisted, isWhitelistSaleOpen, isMintedOut]) => isWhitelisted && isWhitelistSaleOpen && !isMintedOut)
    );
  }
}
