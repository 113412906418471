<section>
  <div class="message-container">
    <div class="logo">
      Compact Games NFT
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
  </div>
</section>
