<div class="container">
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800">
                <div class="tf-title st2 m-b17">
                    <h4 class="title">Join us! </h4>
                </div>
                <p>
                    We are always looking for talented and passionate individuals to join our team and help us push
                    the boundaries of what's possible with non-fungible tokens.
                </p>
                <p>
                    If you have a background in design, marketing, content creation, social media management, software
                    development or any other relevant field and are interested in being a part of a cutting-edge NFT
                    project, we encourage you to apply.
                </p>
                <p>
                    If you're ready to join a team that is making waves in the NFT space and help shape the future of
                    digital art and collectibles, we encourage you to apply by filling the form below.
                </p>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12">
            <form class="form-contact"
                id="contactform"
                data-aos="fade-right"
                data-aos-duration="800"
                [formGroup]="contactForm"
                (ngSubmit)="onSubmit()">
                <fieldset>
                    <input type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        formControlName="firstName">
                    <mat-error *ngIf="firstName.touched && firstName.errors?.required">Name is required</mat-error>
                </fieldset>
                <fieldset>
                    <input type="email"
                        name="mail"
                        id="mail"
                        placeholder="Email"
                        formControlName="email">
                    <mat-error *ngIf="email.touched && email.errors?.required">Email is required</mat-error>
                    <mat-error *ngIf="email.touched && email.errors?.email">Given email is not a correct email
                        address</mat-error>
                </fieldset>
                <fieldset>
                    <input type="text"
                        name="area"
                        id="area"
                        placeholder="Field (ex. marketing, development)"
                        formControlName="area">
                    <mat-error *ngIf="area.touched && area.errors?.required">Field is required</mat-error>
                </fieldset>
                <fieldset>
                    <textarea placeholder="How would you like to help us?"
                        rows="5"
                        name="helpInitiative"
                        class="helpInitiative"
                        id="helpInitiative"
                        formControlName="helpInitiative"></textarea>
                    <mat-error *ngIf="helpInitiative.touched && helpInitiative.errors?.required">This field is required
                    </mat-error>
                </fieldset>
                <fieldset>
                    <textarea placeholder="What do you expect in return?"
                        rows="5"
                        name="expectations"
                        class="expectations"
                        id="expectations"
                        formControlName="expectations"></textarea>
                    <mat-error *ngIf="expectations.touched && expectations.errors?.required">This field is required
                    </mat-error>
                </fieldset>
                <fieldset>
                    <textarea placeholder="Tell us something about yourself"
                        rows="5"
                        name="aboutCandidate"
                        class="aboutCandidate"
                        id="aboutCandidate"
                        formControlName="aboutCandidate"></textarea>
                    <mat-error *ngIf="aboutCandidate.touched && aboutCandidate.errors?.required">This field is required
                    </mat-error>
                </fieldset>
                <button class="tf-button btn-effect"
                    [disabled]="isSending">
                    <span class="boder-fade"></span>
                    <span [hidden]="isSending"><span class="effect">Send Message</span>
                    </span>
                    <img class="cg-loader"
                        [hidden]="!isSending"
                        src="/assets/loaders/loader-black.svg"
                        alt="loader" />
                </button>
            </form>
        </div>
    </div>
</div>
