import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'cg-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  @Input() src: string;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  
  public trustedSrc: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) { }

  public ngOnInit(): void {
    this.trustedSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(this.src);
  }

  public closeVideo(): void {
    this.onClose.emit();
  }
}
