import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AppConstants } from "src/app/common/app-constants";
import { LoggingService } from "src/app/services/logging.service";
import { SubscriptionService } from "src/app/services/subscription.service";

@Component({
  selector: 'cg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public isSending = false;
  public twitterUrl = AppConstants.twitter;
  public discordUrl = AppConstants.discord;
  public tiktokUrl = AppConstants.tiktok;

  public footerLinks = [
    { title: "Join us", url: "/join" },
    { title: "Contact us", url: "/contact" },
    { title: "Collaboration / Partnership", url: "/collaboration" },
  ]

  public subscribeForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  get email() { return this.subscribeForm.get('email'); }

  constructor(
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
    private loggingService: LoggingService
  ) { }

  public onSubmit(): void {
    this.subscribeForm.markAllAsTouched();
    if (this.subscribeForm.invalid)
      return;

    this.isSending = true;
    this.subscriptionService.subscribe(this.email!.value as string)
      .subscribe(
        res => {
          this.subscribeForm.reset();
          this.toastr.success('Confirmation email has been sent to your inbox.', 'Subscription');
          this.isSending = false;
        },
        err => {
          this.toastr.error('Something went wrong.', 'Subscription');
          this.isSending = false;
        },
        () => { this.isSending = false; }
      );
  }

  public logSocialMediaClick(socialMediaName: string): void {
    this.loggingService.logEvent("Go to social media link (footer section)", { name: socialMediaName })
  }
}
