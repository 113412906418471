<cg-hero></cg-hero>
<cg-about></cg-about>
<cg-nfts-showcase></cg-nfts-showcase>
<!-- <cg-roadmap></cg-roadmap> -->
<cg-how-we-work></cg-how-we-work>

<!-- <cg-team></cg-team> -->
<cg-games-sneak-peeks></cg-games-sneak-peeks>
<cg-partners></cg-partners>
<cg-faq></cg-faq>
