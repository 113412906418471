<section class="cg-page-title"
    data-aos="fade-down"
    data-aos-duration="800">
    <h3> Mint </h3>
</section>

<section class="tf-section tf-contact cg-mint">
    <cg-mint *ngIf="isMintOpen$ | async"></cg-mint>
    <cg-whitelist-check *ngIf="!(isMintOpen$ | async)"></cg-whitelist-check>
</section>
