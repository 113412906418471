import { Component, OnInit } from "@angular/core";
import SwiperCore, { Autoplay, FreeMode, Swiper, SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";

@Component({
  selector: 'cg-nfts-showcase',
  templateUrl: './nfts-showcase.component.html',
  styleUrls: ['./nfts-showcase.component.scss']
})
export class NftsShowcaseComponent implements OnInit {

  cdsRow1 = [
    { videoSrc1: "/assets/videos/cds/balloon.mp4" },
    { videoSrc1: "/assets/videos/cds/bors.mp4" },
    { videoSrc1: "/assets/videos/cds/cryptoadz.mp4" },
    { videoSrc1: "/assets/videos/cds/cube-escape.mp4" },
    { videoSrc1: "/assets/videos/cds/emoji-arena.mp4" },
    { videoSrc1: "/assets/videos/cds/escape.mp4" },
    { videoSrc1: "/assets/videos/cds/flappy-shumii.mp4" },
    { videoSrc1: "/assets/videos/cds/hearts.mp4" },
    { videoSrc1: "/assets/videos/cds/hidden.mp4" },
    { videoSrc1: "/assets/videos/cds/jungle-bang.mp4" },
    { videoSrc1: "/assets/videos/cds/locked.mp4" },
    { videoSrc1: "/assets/videos/cds/mfers.mp4" },
    { videoSrc1: "/assets/videos/cds/moonbird.mp4" },
    { videoSrc1: "/assets/videos/cds/shumii.mp4" },
    { videoSrc1: "/assets/videos/cds/the-witch.mp4" },
    { videoSrc1: "/assets/videos/cds/worst-nightmare.mp4" },
  ];

  cdsRow2 = [
    { videoSrc1: "/assets/videos/cds/balloon.mp4" },
    { videoSrc1: "/assets/videos/cds/bors.mp4" },
    { videoSrc1: "/assets/videos/cds/cryptoadz.mp4" },
    { videoSrc1: "/assets/videos/cds/cube-escape.mp4" },
    { videoSrc1: "/assets/videos/cds/emoji-arena.mp4" },
    { videoSrc1: "/assets/videos/cds/escape.mp4" },
    { videoSrc1: "/assets/videos/cds/flappy-shumii.mp4" },
    { videoSrc1: "/assets/videos/cds/hearts.mp4" },
    { videoSrc1: "/assets/videos/cds/hidden.mp4" },
    { videoSrc1: "/assets/videos/cds/jungle-bang.mp4" },
    { videoSrc1: "/assets/videos/cds/locked.mp4" },
    { videoSrc1: "/assets/videos/cds/mfers.mp4" },
    { videoSrc1: "/assets/videos/cds/moonbird.mp4" },
    { videoSrc1: "/assets/videos/cds/shumii.mp4" },
    { videoSrc1: "/assets/videos/cds/the-witch.mp4" },
    { videoSrc1: "/assets/videos/cds/worst-nightmare.mp4" },
  ]

  constructor() { }

  ngOnInit(): void {
    SwiperCore.use([Autoplay, FreeMode]);
  }

  nftsSliderConfig1: SwiperOptions = this.getSwiperConfig(false);
  nftsSliderConfig2: SwiperOptions = this.getSwiperConfig(true);

  stopAutoplay(swiper: SwiperComponent) {
    swiper.swiperRef.autoplay.stop();
  }

  startAutoplay(swiper: SwiperComponent) {
    swiper.swiperRef.autoplay.start();
  }

  playVideo(el: HTMLMediaElement) {
    el.muted = true;
    el.play();
  }

  private getSwiperConfig(reverseDirection: boolean) {
    return {
      spaceBetween: 0,
      grabCursor: true,
      breakpoints: {
        0: {
          slidesPerView: 1,
          freeMode: false,
        },
        650: {
          slidesPerView: 2,
          freeMode: false,
        },
        1000: {
          slidesPerView: 3,
          freeMode: false,
        },
        1300: {
          slidesPerView: 4
        },
        1600: {
          slidesPerView: 5
        }
      },
      loop: true,
      allowTouchMove: true,
      freeMode: true,
      autoplay: {
        delay: 0.3,
        reverseDirection: reverseDirection,
        disableOnInteraction: false
      },
      speed: 2000
    };
  }
}
