<section class="tf-section tf-project pb-mobie">
    <swiper #swiperTop [config]="nftsSliderConfig1" class="swiper-slider swiper-slider-top"
        (mouseleave)="startAutoplay(swiperTop)" (mouseenter)="stopAutoplay(swiperTop)">
        <ng-template swiperSlide *ngFor="let cd of cdsRow1">
            <div class="img-box">
                <video #video class="cd-video" autoplay="" loop="" muted="true" playsinline="" (canplay)="playVideo(video)">
                    <source [src]="cd.videoSrc1" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <!-- <div class="content">
                    <a href="item.html">Taiyo Robotics</a>
                </div> -->
            </div>
        </ng-template>
    </swiper>
<!--
    <swiper #swiperBottom [config]="nftsSliderConfig2" class="swiper-slider"
        (mouseleave)="startAutoplay(swiperBottom)" (mouseenter)="stopAutoplay(swiperBottom)">
        <ng-template swiperSlide *ngFor="let cd of cdsRow2">
            <div class="img-box">
                <video #video class="cd-video" autoplay="" loop="" muted="true" playsinline="" (canplay)="playVideo(video)">
                    <source [src]="cd.videoSrc1" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <div class="content">
                    <a href="item.html">Taiyo Robotics</a>
                </div>
            </div>
        </ng-template>
    </swiper> -->
</section>
