import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CollaborationForm } from "./../models/collaboration-form";
import { ContactForm } from "../models/contact-form";
import { JoinTeamForm } from "../models/join-team-form";

@Injectable()
export class ContactService {

    constructor(
        private httpClient: HttpClient,
        @Inject('BASE_URL') private baseUrl: string) {
    }

    public sendContactForm(form: ContactForm) {
        return this.httpClient.post(`${this.baseUrl}/SendContactForm`, form);
    }

    public sendJoinTeamForm(form: JoinTeamForm) {
        return this.httpClient.post(`${this.baseUrl}/SendJoinTeamForm`, form);
    }

    public sendCollaborationForm(form: CollaborationForm) {
        return this.httpClient.post(`${this.baseUrl}/SendCollaborationForm`, form);
    }
}
