import { Injectable } from "@angular/core";
import { BigNumber, Contract, ethers, Signer, utils } from "ethers";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import CompactGamesNftAbiFile from "src/assets/blockchain/CompactGamesNft.json";
import { environment } from "src/environments/environment";
import { Web3Service } from "./web3.service";
import { ContractContext } from "../models/contracts/compact-games-nft-contract";

const abi = [
  'event ValueChanged(address indexed author, string oldValue, string newValue)',
  'constructor(string value)',
  'function getValue() view returns (string value)',
  'function setValue(string value)'
];

@Injectable({ providedIn: 'root' })
export class CompactGamesNftContractService {

  public mintedTokenCount$ = new BehaviorSubject<number>(0);
  public publicSaleOpen$ = new BehaviorSubject<boolean>(false);
  public presaleOpen$ = new BehaviorSubject<boolean>(false);

  private signerContract: ContractContext;
  private providerContract: ContractContext;

  constructor(private web3Service: Web3Service, private toastr: ToastrService) {
    const abiInterface = new ethers.utils.Interface(CompactGamesNftAbiFile.abi);
    this.web3Service.selectedAccount$.subscribe(address => {
      this.signerContract = new ethers.Contract(environment.compactGamesNftAddress, abiInterface, web3Service.signer) as unknown as ContractContext;
      this.providerContract = new ethers.Contract(environment.compactGamesNftAddress, abiInterface, web3Service.provider) as unknown as ContractContext;

      this.setInitValues();
      this.subscribeToEvents();
    });
  }

  public async mint(numberOfNfts: number): Promise<void> {
    await this.web3Service.login();

    if (!await this.canMint(numberOfNfts, false)) {
      return;
    }

    const nftCost = (await this.providerContract._price()).mul(numberOfNfts);
    const tx = await this.signerContract.mint(numberOfNfts, { value: nftCost })
    await tx.wait();
    this.toastr.success("NFT minted!");
  }

  public async presaleMint(numberOfNfts: number, merkleTreeProof: string[]): Promise<void> {
    await this.web3Service.login();

    if (!await this.canMint(numberOfNfts, true)) {
      return;
    }

    const nftCost = (await this.providerContract._price()).mul(numberOfNfts);
    const tx = await this.signerContract.presaleMint(numberOfNfts, merkleTreeProof, { value: nftCost })
    await tx.wait();
    this.toastr.success("NFT minted!");
  }

  public getMaxTokenCount(): Promise<number> {
    return this.providerContract.maxTokenCount();
  }

  public getNftPrice(): Promise<BigNumber> {
    return this.providerContract._price();
  }

  private async canMint(numberOfNfts: number, presaleMint: boolean): Promise<boolean> {
    const walletAlreadyMinted = presaleMint
      ? await this.providerContract.usedWalletsInPresale(this.web3Service.selectedAccount$.value)
      : await this.providerContract.usedWalletsInPublicSale(this.web3Service.selectedAccount$.value);

    if (walletAlreadyMinted) {
      this.toastr.info("You already minted in the current stage");
      return false;
    }

    const userBalance = await this.web3Service.signer.getBalance();
    const nftCost = (await this.providerContract._price()).mul(numberOfNfts);
    if (userBalance.lt(nftCost)) {
      this.toastr.error("You have not enough eth to mint");
      return false;
    }

    return true;
  }

  private setInitValues(): void {
    this.providerContract.tokenCount().then(tokenCount => this.mintedTokenCount$.next(tokenCount));
    this.providerContract.isPresaleOpen().then(isOpen => this.presaleOpen$.next(isOpen));
    this.providerContract.isPublicSaleOpen().then(isOpen => this.publicSaleOpen$.next(isOpen));
  }

  private subscribeToEvents(): void {
    this.providerContract.on('TokenMinted', (tokenCount) => this.mintedTokenCount$.next(tokenCount));
    this.providerContract.on('PresaleTriggered', (isOpen) => this.presaleOpen$.next(isOpen));
    this.providerContract.on('PublicSaleTriggered', (isOpen) => this.publicSaleOpen$.next(isOpen));
  }
}
