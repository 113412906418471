import { Injectable } from "@angular/core";
import { ethers } from "ethers";
import { MerkleTree } from "merkletreejs";

@Injectable()
export class WhitelistService {

    private whitelist: string[] = [
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b1",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b2",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b3",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b4",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b5",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b6",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b7",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b7",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad5b8",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad511",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad512",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad513",
        "0x334b4b37412590aaeba5b7ef96dc36f7c34ad514"
    ];

    public isWhitelisted(address: string): boolean {
        // console.log("Merkle tree root:\n", this.getMerkleTreeRoot());
        return this.whitelist.some(x => x.toLowerCase() === address.toLowerCase());
    }

    public getMerkleTreeRoot(): string {
        const { keccak256 } = ethers.utils
        let leaves = this.whitelist.map((addr) => keccak256(addr))
        const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true })

        // Save this value to smartcontract
        return merkleTree.getHexRoot()
    }

    public getMerkleTreeProof(userWalletAddress: string): string[] {
        if (this.isWhitelisted(userWalletAddress)) {
            const { keccak256 } = ethers.utils
            let leaves = this.whitelist.map((addr) => keccak256(addr))
            const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true })
            let hashedAddress = keccak256(userWalletAddress)
            return merkleTree.getHexProof(hashedAddress)
        }

        return [];        
    }
}
