import { Component, OnInit } from "@angular/core";
import { AppConstants } from "src/app/common/app-constants";

@Component({
  selector: 'cg-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  aboutItems = [
    {
      title: "Game as NFT",
      description: "Compact Games NFT is focused on innovation. It brings finest ideas into reality by utilizing Web 3.0 and everything it offers. Compact Games NFT is not only about games but about the whole ecosystem that we build! Apart from giving an entertaining utility to owners it is used as an access pass to the future platforms that are currently in progress."
    },
    {
      title: "Access pass",
      description: "Compact Games NFTs will work as an access pass to the future initiatives of the team. Guaranteed access to the future collections and platforms developed by the team where owners of Compact Games NFTs."
    },
    {
      title: "Exclusive collection",
      description: `There will be only ${AppConstants.nftsCount} game CDs available making the whole experience limited to the very small group of individuals. Only selected will have guaranteed place in the platforms and tools developed by the team.`
    },
    {
      title: "Win big prizes",
      description: "To increase engagement the team will provide many immersive competitions to the owners. Players will be able to use their CDs to play the games, get collectibles and win prizes. Cross games competitions will not only give players opportunities to win prizes but also they will increase demand for specific games."
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}