import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'cg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() smallButton: boolean = false;
  @Input() padding: string = "0 40px";

  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public onButtonClick($event: any): void {
    this.buttonClick.emit($event);
  }
}
