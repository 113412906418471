import { Injectable, NgZone } from "@angular/core";
import { providers, utils, ethers } from "ethers";
// import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { NetworkModel, NetworkService } from "./network.service";
import { Web3OnboardService } from "./web3-onboard.service";
import { Network } from "../enums/network";

@Injectable({ providedIn: 'root' })
export class Web3Service {

  public selectedAccount$: BehaviorSubject<string> = new BehaviorSubject(null);
  public selectedChain$: BehaviorSubject<number> = new BehaviorSubject(null);

  private _wsAnkrProvider: providers.WebSocketProvider;
  public get wsAnkrProvider(): providers.WebSocketProvider {
    if (this._wsAnkrProvider) {
      return this._wsAnkrProvider;
    } else {
      const errorMsg = "Ankr provider not found";
      throw Error(errorMsg);
    }
  }


  private _provider: providers.Web3Provider;
  public get provider(): providers.Web3Provider {
    if (this._provider) {
      return this._provider;
    } else {
      const errorMsg = "Web3 provider not found";
      // this.toastr.error(errorMsg);
      // this.router.navigate(["/"]);

      // return null;
      throw Error(errorMsg);
    }
  }

  private _signer: providers.JsonRpcSigner = null;
  public get signer(): providers.JsonRpcSigner {
    return this._signer;
  }

  constructor(
    private web3OnboardService: Web3OnboardService,
    private ngZone: NgZone
  ) {
    this.selectedChain$.subscribe(chainId => this.setAnkrProvider(chainId));
    this.web3OnboardService.connectedWallets$.subscribe(wallets => {
      if (!this._provider && wallets[0]) {
        this.setProvider(wallets[0]);
      }
    });
  }

  public async login(): Promise<void> {
    if (this.selectedAccount$.value) {
      return;
    }

    const wallets = await this.web3OnboardService.connectWallet();
    if (wallets[0]) {
      this.setProvider(wallets[0]);
    }
  }

  public async logout(): Promise<void> {
    await this.web3OnboardService.disconnectWallet();
    this._provider = null;
    this.ngZone.run(() => { this.selectedAccount$.next(null); });
  }

  public changeNetwork(network: Network): void {
    this.provider.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: utils.hexValue(network) }]
    }).catch(error => {
      if (error?.code === 4902) {
        this.addNetwork(NetworkService.getNetwork(network));
      }
    });
  }

  public addNetwork(network: NetworkModel): Promise<unknown> {
    return this.provider.provider.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: utils.hexValue(network.id),
        rpcUrls: network.rpcUrls,
        chainName: network.displayName,
        nativeCurrency: network.nativeCurrency,
        blockExplorerUrls: network.blockExplorerUrls
      }]
    });
  }

  public async signMessage(message: string): Promise<string> {
    return await this.signer.signMessage(message);
  }

  private setAnkrProvider(chainId: number) {
    const network = NetworkService.getNetwork(chainId);
    this._wsAnkrProvider = network.id === Network.Unknown
      ? null
      : new providers.WebSocketProvider(network.wsAnkrRpc, network.id);
  }

  private async setProvider(wallet: any): Promise<void> {
    this._provider = new ethers.providers.Web3Provider(wallet.provider, 'any');

    this.handleConnect();
    this.handleAccountsChanged();
    this.handleChainChanged();

    this.setSigner();
  }

  private async setSigner(): Promise<void> {
    const accounts = await this.provider.send("eth_requestAccounts", []);
    this._signer = await this.provider.getSigner();

    if (accounts[0] !== this.selectedAccount$.value) {
      this.ngZone.run(() => { this.selectedAccount$.next(accounts[0]); });
    }
  }

  private handleConnect(): void {
    (this.provider.provider as any).on('connect', async (connectInfo) => {
      this.setSigner();
    });
  }

  private async handleAccountsChanged(): Promise<void> {
    (this.provider.provider as any).on('accountsChanged', async (accounts) => {
      const address = accounts[0] ?? null;
      if (!address) {
        this._signer = null;
        this.ngZone.run(() => { this.selectedAccount$.next(address); });
      } else {
        await this.setSigner();
      }
    });
  }

  private handleChainChanged(): void {
    this.provider.getNetwork().then(network => {
      this.selectedChain$.next(network.chainId);
    });

    // window.ethereum.on('chainChanged', (chainId) => {
    (this.provider.provider as any).on('chainChanged', (chainId) => {
      // provider.on('chainChanged', (chainId) => {
      // this.setProviderAndSigner();
      this.ngZone.run(() => { this.selectedChain$.next(Number(chainId)); });
      // this.selectedChain$.next(Number(chainId));
      // window.location.reload(); 
    });
  }
}
