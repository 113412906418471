<section class="tf-section item-post">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="tf-title m-b26" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
          <p class="h8 sub-title">CD GAMES</p>
          <h4 class="title">Games sneak-peeks</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="sneak-peeks-container" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
    <div *ngFor="let game of games" class="post-item active">
      <div>
        <div class="post-header">
          <div class="h7 title">{{game.name}}</div>
        </div>
        <div class="image">
          <img [src]="game.thumbnail" />
        </div>
        <div class="content">
          <button (click)="triggerVideo(game)" class="tf-button btn-effect">
            <span class="boder-fade"></span>
            <span class="effect">Watch gameplay</span>
          </button>
          <cg-video *ngIf="game.isVideoOpen" [src]="game.videoUrl" (onClose)="triggerVideo(game)"></cg-video>
        </div>
      </div>
    </div>
  </div>
</section>