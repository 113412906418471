import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

@Injectable()
export class SubscriptionService {

  constructor(
    private httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) {
  }

  public subscribe(email: string) {
    return this.httpClient.post(`${this.baseUrl}/Subscribe`, { email: email });
  }
}
