import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { NetworkService } from "src/app/services/network.service";
import { Web3Service } from "src/app/services/web3.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'cg-network-handler',
  templateUrl: './network-handler.component.html',
  styleUrls: ['./network-handler.component.scss']
})
export class NetworkHandlerComponent implements OnInit {

  public supportedNetworkName: string;
  public isSuportedNetworkSelected$: Observable<boolean>;

  constructor(private web3Service: Web3Service) { }

  public ngOnInit(): void {
    const network = NetworkService.getNetwork(environment.network);
    this.supportedNetworkName = network.displayName;
    this.isSuportedNetworkSelected$ = this.web3Service.selectedChain$.pipe(
      map(networkId => networkId === null || network.id == networkId),
      tap(isSupported => {
        if (!isSupported) {
          this.web3Service.changeNetwork(environment.network);
        }
      })
    );
  }

  public changeNetwork(): void {
    this.web3Service.changeNetwork(environment.network);
  }
}
