<section class="tf-section tf-about pb-mobie">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="content-about mobie-40"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <div class="tf-title st2">
                        <p class="h8 sub-title">About us</p>
                        <h4 class="title">What is Compact Games NFT?</h4>
                    </div>
                    <p class="cg-description">
                        Compact Games NFT is focused on innovation. It brings finest ideas into reality by utilizing Web
                        3.0 and
                        everything it offers.
                        Compact Games NFT is not only about games but about the whole ecosystem that we build! Apart
                        from giving
                        an entertaining
                        utility to owners it is used as an access pass to the future platforms that are currently in
                        progress.
                    </p>
                    <img class="promo-image" src="/assets/images/console-the-witch.webp" />
                    <!-- <a class="tf-button st2 btn-effect"
                        href="item.html">
                        <span class="boder-fade"></span>
                        <span class="effect">get Nfts</span>
                    </a> -->
                </div>
            </div>
            <div class="col-lg-7">
                <div class="wrap-about p-l70"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <div class="box-text corner-box active"
                        *ngFor="let item of aboutItems">
                        <div class="h7">{{item.title}}</div>
                        <p>{{item.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
