import { Component } from "@angular/core";
import { SneakPeek } from "src/app/models/sneak-peek";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: 'cg-games-sneak-peeks',
  templateUrl: './games-sneak-peeks.component.html',
  styleUrls: ['./games-sneak-peeks.component.scss']
})
export class GamesSneakPeeksComponent {

  public games: SneakPeek[] = [
    {
      name: "The Witch",
      type: "Adventure",
      description: "The witch game desc",
      thumbnail: "/assets/images/gameplays/the-witch-thumbnail.webp",
      videoUrl: "https://www.youtube.com/embed/00oQpnR3EK8?si=X-gBtLyFmKYj-4Bz",
      isVideoOpen: false
    },
    {
      name: "Worst Nightmare",
      type: "Arcade",
      description: "Worst Nightmare game desc",
      thumbnail: "/assets/images/gameplays/spirit-halloween-thumbnail.webp",
      videoUrl: "https://www.youtube.com/embed/I_CFnjWyOtU?si=GU5BX6FUjPJJMhuV",
      isVideoOpen: false
    },
    {
      name: "Shumii",
      type: "Arcade",
      description: "Shumii game desc",
      thumbnail: "/assets/images/gameplays/shumii-thumbnail.webp",
      videoUrl: "https://www.youtube.com/embed/3bFl2o5uQgc?si=AHkKY9dMNHwQfrh_",
      isVideoOpen: false
    }
  ]

  constructor(private loggingService: LoggingService) { }

  public triggerVideo(game: SneakPeek): void {
    game.isVideoOpen = !game.isVideoOpen;
    const eventName = game.isVideoOpen
      ? "Open sneak peek video (sneak-peeks section)"
      : "Close sneak peek video (sneak-peeks section)";
    this.loggingService.logEvent(eventName, { "game": game.name })
  }
}
