import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'cg-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss']
})
export class RoadmapComponent implements OnInit {

  roadmapItems = [
    { title: "Website", description: "Compact Games website launch", isDone: true },
    { title: "Games platform", description: "Release of game platform", isDone: false },
    { title: "NFT Collabs", description: "Release of games inspired by other NFT projects", isDone: false },
    { title: "Compact Games NFT", description: "Core NFT collection release", isDone: false },
    { title: "NFT games platform", description: "Release of game platform for NFT holders", isDone: false },
    { title: "Compete and earn", description: "Launch of game competition series for NFT holders with rewards", isDone: false },
    { title: "Avatars collection", description: "Release of avatars collection", isDone: false },
    { title: "Meta Fantasy", description: "Here avatars come to play...", isDone: false },
    { questionMarks: true },
    { questionMarks: true },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
