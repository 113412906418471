<div class="input-container">
    <cg-button padding="0 20px"
        [disabled]="disabled"
        (click)="decrement()">
        <fa-icon [icon]="faMinus"></fa-icon>
    </cg-button>
    <input type="number"
        [min]="min"
        [max]="max"
        [disabled]="true"
        [placeholder]="placeholder"
        [(value)]="value">
    <cg-button padding="0 20px"
        [disabled]="disabled"
        (click)="increment()">
        <fa-icon [icon]="faPlus"></fa-icon>
    </cg-button>
</div>
