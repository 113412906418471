import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { WhitelistService } from "src/app/services/whitelist.service";
import { Web3Service } from "./../../../../services/web3.service";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: 'cg-whitelist-check',
  templateUrl: './whitelist-check.component.html',
  styleUrls: ['./whitelist-check.component.scss']
})
export class WhitelistCheckComponent {

  public isWalletWhitelisted?: boolean = null;

  public whitelistCheckForm = new UntypedFormGroup({
    walletAddress: new UntypedFormControl('', [Validators.required]),
  });

  get walletAddress() { return this.whitelistCheckForm.get('walletAddress'); }

  constructor(
    private whitelistService: WhitelistService,
    private loggingService: LoggingService
  ) { }

  public checkEligibility(): void {
    this.loggingService.logEvent("Check eligibility", { "valid": this.whitelistCheckForm.valid, "wallet": this.walletAddress.value })
    this.whitelistCheckForm.markAllAsTouched();
    if (this.whitelistCheckForm.invalid)
      return;

    this.isWalletWhitelisted = this.whitelistService.isWhitelisted(this.walletAddress.value);
  }
}
