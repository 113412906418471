import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'cg-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent {

  @Input() min: number = 1;
  @Input() max: number = 10;
  @Input() value: number;
  @Input() placeholder: string;
  @Input() disabled: boolean = false;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  public faPlus: IconDefinition = faPlus;
  public faMinus: IconDefinition = faMinus;

  public increment(): void {
    if (this.value < this.max && !this.disabled) {
      this.value++;
      this.emitValueChanged();
    }
  }

  public decrement(): void {
    if (this.value > this.min && !this.disabled) {
      this.value--;
      this.emitValueChanged();
    }
  }

  public emitValueChanged(): void {
    this.valueChange.emit(this.value);
  }
}
