<section class="cg-page-title"
    data-aos="fade-down"
    data-aos-duration="800">
    <h3>{{ activeItem.label }}</h3>
</section>

<section class="tf-section tf-contact cg-contact">
    <div class="container">
        <div class="form-types-container"
            data-aos="fade-down"
            data-aos-duration="800">
            <ul>
                <li *ngFor="let navItem of helpItems">
                    <a [routerLinkActive]='["active"]'
                        [routerLink]="[navItem.url]"
                        class="nav-item tf-button-st2 btn-effect">
                        <span class="effect">{{navItem.label}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <router-outlet></router-outlet>
    </div>
</section>
