import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";

@Injectable()
export class LoggingService {
  private appInsights: ApplicationInsights | null;

  constructor() {
    this.appInsights = environment.production
      ? new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsInstrumentationKey,
          enableAutoRouteTracking: true // option to log all route changes
        }
      })
      : null;
    this.appInsights?.loadAppInsights();
  }

  public setAuthenticatedUserContext(authenticatedUserId: string) {
    this.appInsights?.setAuthenticatedUserContext(authenticatedUserId, null, true);
  }

  public clearAuthenticatedUserContext() {
    this.appInsights?.clearAuthenticatedUserContext();
  }

  public logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights?.trackPageView({ name: name, uri: url });
  }

  public logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights?.trackEvent({ name: name }, properties);
  }

  public logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights?.trackMetric({ name: name, average: average }, properties);
  }

  public logException(exception: Error, severityLevel?: number) {
    this.appInsights?.trackException({ exception: exception, severityLevel: severityLevel });
  }

  public logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights?.trackTrace({ message: message }, properties);
  }

  public startTrackEvent(name: string) {
    this.appInsights?.startTrackEvent(name);
  }

  public stopTrackEvent(name: string, properties?: { [key: string]: string; }, measurements?: { [key: string]: number; }) {
    this.appInsights?.stopTrackEvent(name, properties, measurements);
  }
}
