<section class="tf-section how-we-work bg-st1">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="tf-title st2 m-b60"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <p class="h8 sub-title">Compact Games NFT platform</p>
                    <h4 class="title m-b17">How it works</h4>
                </div>
            </div>
            <div class="how-it-works-items">
                <div class="box-text st2 corner-box active"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="800"
                    *ngFor="let item of instructionItems; let i = index">
                    <div class="number">0{{i + 1}}</div>
                    <div class="h7">{{item.title}}</div>
                    <p>{{item.description}}</p>
                </div>
            </div>
        </div>
    </div>
</section>