import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'cg-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.scss']
})
export class HowWeWorkComponent implements OnInit {

  instructionItems = [
    { title: "Get Compact Games NFT", description: "Get a virtual CD in form of NFT. You can mint it or get it on the secondary market after collection release." },
    { title: "Connect your wallet", description: "Connect your wallet on Compact Games virtual console platform using a provider of your choice." },
    { title: "Choose the game", description: "From the list of available virtual CDs select one of your Compact Games NFT." },
    { title: "Enjoy the game!", description: "Put the game CD into the virtual console to run it. Play and enjoy the experience!" },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
