import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ContactService } from "src/app/services/contact.service";
import { CollaborationForm } from "./../../../../models/collaboration-form";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: 'cg-collaboration',
  templateUrl: './collaboration.component.html',
  styleUrls: ['./collaboration.component.scss']
})
export class CollaborationComponent {

  isSending = false;

  contactForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    website: new UntypedFormControl(''),
    twitter: new UntypedFormControl('', [Validators.required]),
    discord: new UntypedFormControl(''),
    projectDescription: new UntypedFormControl('', [Validators.required]),
    collabDescription: new UntypedFormControl('', [Validators.required]),
  });

  get firstName() { return this.contactForm.get('firstName'); }
  get email() { return this.contactForm.get('email'); }
  get website() { return this.contactForm.get('website'); }
  get twitter() { return this.contactForm.get('twitter'); }
  get discord() { return this.contactForm.get('discord'); }
  get projectDescription() { return this.contactForm.get('projectDescription'); }
  get collabDescription() { return this.contactForm.get('collabDescription'); }

  constructor(
    private contactService: ContactService,
    private toastr: ToastrService,
    private loggingService: LoggingService,
  ) { }

  public onSubmit(): void {
    this.loggingService.logEvent("Submit collaboration form", { "valid": this.contactForm.valid, "form": this.contactForm.value })
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid)
      return;

    const form: CollaborationForm = this.contactForm.getRawValue();
    this.isSending = true;
    this.contactService.sendCollaborationForm(form)
      .subscribe(
        res => {
          this.contactForm.reset();
          this.toastr.success('Your message has been sent.');
          this.isSending = false;
        },
        err => {
          this.toastr.error('Something went wrong.');
          this.isSending = false;
          throw (err);
        },
        () => { this.isSending = false; }
      );
  }
}
