import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ComingSoonComponent } from "./components/miscellaneous/coming-soon/coming-soon.component";
import { SubscriptionConfirmationComponent } from "./components/miscellaneous/subscription/subscription-confirmation/subscription-confirmation.component";
import { CollaborationComponent } from "./components/website/contact/collaboration/collaboration.component";
import { ContactUsComponent } from "./components/website/contact/contact-us/contact-us.component";
import { ContactComponent } from "./components/website/contact/contact.component";
import { JoinTeamComponent } from "./components/website/contact/join-team/join-team.component";
import { LandingPageComponent } from "./components/website/landing-page/landing-page/landing-page.component";
import { MintPageComponent } from "./components/website/mint-page/mint-page.component";
import { VirtualConsoleComponent } from "./components/website/virtual-console/virtual-console/virtual-console.component";
import { WebsiteComponent } from "./components/website/website/website.component";

const routes: Routes = [
  // { path: '', component: ComingSoonComponent },
  {
    path: '', component: WebsiteComponent, children: [
      { path: '', component: LandingPageComponent },
      { path: 'play', component: VirtualConsoleComponent },
      { path: 'mint', component: MintPageComponent },
      {
        path: '', component: ContactComponent, children: [
          { path: 'contact', component: ContactUsComponent },
          { path: 'join', component: JoinTeamComponent },
          { path: 'collaboration', component: CollaborationComponent }
        ],
      }
    ]
  },
  { path: 'subscription-confirmation', component: SubscriptionConfirmationComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
