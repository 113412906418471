import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";
import { faCompactDisc, faWallet, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "rxjs";
import { GameCd } from "src/app/models/game-cd";
import { LoggingService } from "src/app/services/logging.service";
import { Web3Service } from "src/app/services/web3.service";

@Component({
  selector: 'cg-virtual-console',
  templateUrl: './virtual-console.component.html',
  styleUrls: ['./virtual-console.component.scss']
})
export class VirtualConsoleComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  public isWalletConnected: boolean;
  public selectedGameUrl: SafeResourceUrl = null;
  public faCompactDisc: IconDefinition = faCompactDisc;
  public faWallet: IconDefinition = faWallet;
  public isFullScreen: boolean = false;

  public games: Array<GameCd> = [
    { gameUrl: "assets/games/the-witch/index.html", imageUrl: "assets/images/cds/the-witch-cd.webp", title: "The Witch" },
    { gameUrl: "assets/games/spirit-halloween/index.html", imageUrl: "assets/images/cds/spirit-halloween-cd.webp", title: "Spirit Halloween" },
    { gameUrl: "assets/games/hearts/index.html", imageUrl: "assets/images/cds/hearts-cd.webp", title: "Don't let break your heart" },
  ]

  constructor(
    public domSanitizer: DomSanitizer,
    private web3Service: Web3Service,
    private loggingService: LoggingService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    this.subscription = this.web3Service.selectedAccount$.subscribe(address => {
      this.isWalletConnected = address !== null
      this.changeDetectorRef.detectChanges();
    });
  }

  public selectGame(game: GameCd): void {
    this.selectedGameUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(game.gameUrl);
    this.triggerFullScreen();
    this.loggingService.logEvent("Game selection (virtual console section)", { name: game.title });
  }

  public async connect(): Promise<void> {
    await this.web3Service.login();
  }

  public logSelectGameClick(): void {
    this.loggingService.logEvent("Select game click (virtual console section)");
  }

  public triggerFullScreen(): void {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      window.document.body.classList.add("cg-body-full-screen");
      window.document.documentElement.requestFullscreen();
    } else {
      window.document.body.classList.remove("cg-body-full-screen");
      window.document.exitFullscreen();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
