import { Component, OnInit } from "@angular/core";
import SwiperCore, { Keyboard, Navigation, Pagination } from "swiper";

@Component({
  selector: 'cg-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {

  public windowScrolled: boolean = false;

  constructor() { }

  ngOnInit(): void {
    SwiperCore.use([Navigation, Keyboard, Pagination]);

    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset > 800;
    });
  }

  public scrollTop() {
    window.scrollTo(0, 0);
  }
}
