<section class="tf-section partner bg-st1 pb-mobie-40">
    <div class="container featured-in-games">
        <div class="row">
            <div class="col-md-12">
                <div class="tf-title"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <p class="h8 sub-title">Special editions</p>
                    <h4 class="title">Featured in our games</h4>
                </div>
            </div>
            <div class="partners-container">
                <div class="col-md-12 partner-item"
                    *ngFor="let game of featuredInGames">
                    <div class="item-parner"
                        data-aos="fade-up"
                        data-aos-duration="800">
                        <div class="image">
                            <img [src]="game.image" [alt]="game.name">
                            <div class="question-marks">
                                <fa-icon [icon]="faQuestion"></fa-icon>
                                <fa-icon [icon]="faQuestion"></fa-icon>
                                <fa-icon [icon]="faQuestion"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container partners">
        <div class="row">
            <div class="col-md-12">
                <div class="tf-title"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <p class="h8 sub-title">Partnerships and collaborations</p>
                    <h4 class="title">Our partners</h4>
                </div>
            </div>
            <div class="partners-container">
                <div class="col-md-12 partner-item"
                    *ngFor="let partner of partners">
                    <div class="item-parner"
                        data-aos="fade-up"
                        data-aos-duration="800">
                        <div class="image">
                            <img [src]="partner.image">
                            <div class="question-marks">
                                <fa-icon [icon]="faQuestion"></fa-icon>
                                <fa-icon [icon]="faQuestion"></fa-icon>
                                <fa-icon [icon]="faQuestion"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
