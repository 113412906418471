import { Component, OnInit } from "@angular/core";
import SwiperCore, { Keyboard, Navigation, Pagination } from "swiper";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public ngOnInit(): void {
    SwiperCore.use([Navigation, Keyboard, Pagination]);
  }
}
