<div class="container">
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800">
                <div class="tf-title st2 m-b17">
                    <h4 class="title">Have a question? </h4>
                </div>
                <p>
                    We are always happy to hear from members of the community and welcome any questions or feedback you
                    may have. Whether you have a question about our project or just want to share your thoughts, we want
                    to hear from you.
                </p>
                <p>
                    To get in touch, you can use the contact form provided on this page. Simply fill out the form with
                    your name, email address, and message, and one of our team members will get back to you as soon as
                    possible.
                </p>
                <p>
                    We look forward to hearing from you and engaging with the NFT community! Thank you for your interest
                    in our project.
                </p>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12">
            <form class="form-contact"
                id="contactform"
                data-aos="fade-right"
                data-aos-duration="800"
                [formGroup]="contactForm"
                (ngSubmit)="onSubmit()">
                <fieldset>
                    <input type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        formControlName="firstName">
                    <mat-error *ngIf="firstName.touched && firstName.errors?.required">Name is required</mat-error>
                </fieldset>
                <fieldset>
                    <input type="email"
                        name="mail"
                        id="mail"
                        placeholder="Email"
                        formControlName="email">
                    <mat-error *ngIf="email.touched && email.errors?.required">Email is required</mat-error>
                    <mat-error *ngIf="email.touched && email.errors?.email">Given email is not a correct email
                        address</mat-error>
                </fieldset>
                <fieldset>
                    <textarea placeholder="Type your Messege"
                        rows="5"
                        name="message"
                        class="message"
                        id="message"
                        formControlName="content"></textarea>
                    <mat-error *ngIf="content.touched && content.errors?.required">Content is required</mat-error>
                </fieldset>
                <button class="tf-button btn-effect"
                    [disabled]="isSending">
                    <span class="boder-fade"></span>
                    <span [hidden]="isSending"><span class="effect">Send Message</span>
                    </span>
                    <img class="cg-loader"
                        [hidden]="!isSending"
                        src="/assets/loaders/loader-black.svg"
                        alt="loader" />
                </button>
            </form>
        </div>
    </div>
</div>
