import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ContactForm } from "src/app/models/contact-form";
import { NavItem } from "src/app/models/nav-item";
import { ContactService } from "src/app/services/contact.service";

@Component({
  selector: 'cg-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  rootPath: string = '';
  helpItems: NavItem[] = [
    { label: 'Contact us', url: `${this.rootPath}/contact` },
    { label: 'Join the team', url: `${this.rootPath}/join` },
    { label: 'Collaboration / Partnership', url: `${this.rootPath}/collaboration` },
  ];
  get activeItem(): NavItem {
    return this.helpItems.find(x => x.url == this.router.url);
  }

  constructor(private router: Router) { }
}
