export enum Network {
    Unknown = -1,
    Ethereum = 1,
    Sepolia = 11155111,
    Base = 8453,
    BaseSepolia = 84532,
    Polygon = 137,
    PolygonZkEvm = 1101,
    Mumbai = 80001,
    Blast = 81457,
    BlastSepolia = 168587773,
    Avalanche = 43114,
    Fuji = 43113,
    BinanceSmartChain = 56,
    Optimism = 10,
    ArbitrumOne = 42161,
    AbstractTestnet = 11124
}