import { ErrorHandler, Inject, Injectable, Injector, NgZone } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        @Inject(Injector) private readonly injector: Injector,
        private zone: NgZone
    ) { }

    handleError(error: any) {

        if (error?.rejection) {
            error = error.rejection; // get the error object
        }

        let errorMessage: string;
        if (error?.code === -32002) {
            errorMessage = "Wallet connection request is pending. Please wait...";
        }

        if (error?.code === 4001) {
            errorMessage = "Action rejected.";
        }

        if (errorMessage) {
            this.zone.run(() => this.toastr.success(errorMessage, null, { toastClass: "ngx-toastr no-icon", extendedTimeOut: 5000 }));
        }

        const errorCodesThatShouldNotBeLogged = [-32002, 4001];
        if (!errorCodesThatShouldNotBeLogged.includes(error?.code)) {
            console.error('Error from global error handler', error);
        }
    }

    private get toastr(): ToastrService {
        return this.injector.get(ToastrService);
    }
}
