<div class="mint-container"
    data-aos="fade-down"
    data-aos-duration="800">
    <video #video
        class="cd-video"
        autoplay=""
        loop=""
        muted="true"
        playsinline=""
        (canplay)="playVideo(video)">
        <source src="/assets/videos/cds/hidden-cd.webm"
            type="video/mp4">
        Your browser does not support the video tag.
    </video>
    <h5>Minted {{ mintedTokenCount$ | async }} / {{ maxTokenCount$ | async }}</h5>

    <div *ngIf="!(isMintedOut$ | async)"
        class="minting-container">
        <div class="nft-counter">
            <cg-number-input [(value)]="selectedNftCount"
                [min]="1"
                [max]="3"
                [placeholder]="1"
                [disabled]="isMintedOut$ | async"></cg-number-input>
            <p *ngIf="nftPrice"
                class="total-price">{{nftPrice * selectedNftCount}} eth</p>
        </div>
        <cg-button *ngIf="isPublicSaleOpen$ | async"
            class="mint-btn"
            (buttonClick)="publicMint()"
            [isLoading]="isMinting">Mint</cg-button>
        <div *ngIf="!(isPublicSaleOpen$ | async)">
            <cg-button class="mint-btn"
                (buttonClick)="whitelistMint()"
                [isLoading]="isMinting"
                [disabled]="!(isPresaleMintButtonEnabled$ | async)">Whitelist mint</cg-button>
            <div *ngIf="!(this.isWhitelisted$ | async)"
                class="eligibility-msg">You are not eligible for whitelist</div>
        </div>
    </div>
</div>
