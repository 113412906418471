import { Component } from "@angular/core";

@Component({
  selector: 'app-subscription-cancellation',
  templateUrl: './subscription-cancellation.component.html',
  styleUrls: ['./subscription-cancellation.component.scss']
})
export class SubscriptionCancellationComponent {

  constructor() {
  }
}
