<header id="header_main" class="header">
  <div class="container">
    <div id="site-header-inner">
      <div class="header__logo">
        <a routerLink="/"><img class="logo" src="assets/images/logo/logo.svg" alt=""></a>
      </div>
      <nav id="main-nav" class="main-nav">
        <ul id="menu-primary-menu" class="menu">
          <li *ngFor="let item of menuItems" class="menu-item" routerLinkActive="current-menu-item"
            [routerLinkActiveOptions]="{exact: true}">
            <button class="menu-button" [routerLink]="item.url" [disabled]="item.isDisabled">{{item.title}}</button>
          </li>

          <li class="menu-item mobile-only">
            <button class="menu-button" (click)="connect()">
              <span *ngIf="!connectedAddress">Connect wallet</span>
              <span *ngIf="connectedAddress" class="wallet-address">{{ shortenedAddress }}</span>
              <i *ngIf="connectedAddress" class="fas fa-wallet"></i>
            </button>
          </li>
        </ul>
      </nav>
      <div class="buttons-right-container desktop-only">
        <!-- <button class="tf-button btn-effect">
                    <span class="boder-fade"></span>
                    <span class="effect"><i class="fas fa-user"></i></span>
                </button> -->
        <button class="tf-button btn-effect connect-btn" [class.cg-active]="connectedAddress"
          [matTooltip]="connectTooltip" matTooltipClass="wallet-tooltip" (click)="connect()">
          <span class="boder-fade"></span>
          <span class="effect">
            <span *ngIf="connectedAddress" class="wallet-address">{{ shortenedAddress }}</span>
            <i class="cg-icon connect-icon fas fa-wallet"></i>
            <i class="cg-icon disconnect-icon fas fa-sign-out-alt"></i>
          </span>
        </button>
      </div>
      <div class="mobile-button"><span></span></div><!-- /.mobile-button -->
    </div>
  </div>
</header>