import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ContactForm } from "src/app/models/contact-form";
import { ContactService } from "src/app/services/contact.service";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: 'cg-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  isSending = false;

  contactForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    content: new UntypedFormControl('', [Validators.required]),
  });

  get firstName() { return this.contactForm.get('firstName'); }
  get email() { return this.contactForm.get('email'); }
  get content() { return this.contactForm.get('content'); }

  constructor(
    private contactService: ContactService,
    private toastr: ToastrService,
    private loggingService: LoggingService
  ) { }

  public onSubmit(): void {
    this.loggingService.logEvent("Submit contact us form", { "valid": this.contactForm.valid, "form": this.contactForm.value })
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid)
      return;

    const form: ContactForm = this.contactForm.getRawValue();
    this.isSending = true;
    this.contactService.sendContactForm(form)
      .subscribe(
        res => {
          this.contactForm.reset();
          this.toastr.success('Your message has been sent.');
          this.isSending = false;
        },
        err => {
          this.toastr.error('Something went wrong.');
          this.isSending = false;
          throw (err);
        },
        () => { this.isSending = false; }
      );
  }
}
