<div class="container">
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12">
            <div class="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800">
                <div class="tf-title st2 m-b17">
                    <h4 class="title">Innovate with us</h4>
                </div>
                <p>
                    We are always open to exploring opportunities for collaboration and partnerships with individuals
                    and teams who share our vision for the future of non-fungible tokens.
                </p>
                <p>
                    If you are interested in collaborating with us to create unique NFT experiences we encourage you to
                    get in touch. We are always looking for new and innovative ways to showcase the potential of NFTs
                    and are excited to see what we can create together.
                </p>
                <p>
                    We believe that NFTs have the potential to revolutionize the way that art and collectibles are
                    created, collected, and shared, and are excited to explore potential partnerships that can help us
                    achieve this goal.
                </p>
                <p>
                    To start a conversation about collaboration or partnership, please use the contact form provided on
                    this page. We look forward to hearing from you and exploring the possibilities!
                </p>
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12">
            <form class="form-contact"
                id="contactform"
                data-aos="fade-right"
                data-aos-duration="800"
                [formGroup]="contactForm"
                (ngSubmit)="onSubmit()">
                <fieldset>
                    <input type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        formControlName="firstName">
                    <mat-error *ngIf="firstName.touched && firstName.errors?.required">Name is required</mat-error>
                </fieldset>
                <fieldset>
                    <input type="email"
                        name="mail"
                        id="mail"
                        placeholder="Email"
                        formControlName="email">
                    <mat-error *ngIf="email.touched && email.errors?.required">Email is required</mat-error>
                    <mat-error *ngIf="email.touched && email.errors?.email">Given email is not a correct email
                        address</mat-error>
                </fieldset>
                <fieldset>
                    <input type="text"
                        name="website"
                        id="website"
                        placeholder="Website"
                        formControlName="website">
                </fieldset>
                <fieldset>
                    <input type="text"
                        name="twitter"
                        id="twitter"
                        placeholder="Twitter handle (ex. @elonmusk)"
                        formControlName="twitter">
                    <mat-error *ngIf="twitter.touched && twitter.errors?.required">Twitter is required</mat-error>
                </fieldset>
                <fieldset>
                    <input type="text"
                        name="discord"
                        id="discord"
                        placeholder="Discord url"
                        formControlName="discord">
                </fieldset>
                <fieldset>
                    <textarea placeholder="Tell us something about you and the project you're part of"
                        rows="5"
                        name="projectDescription"
                        class="projectDescription"
                        id="projectDescription"
                        formControlName="projectDescription"></textarea>
                    <mat-error *ngIf="projectDescription.touched && projectDescription.errors?.required">This field is
                        required</mat-error>
                </fieldset>
                <fieldset>
                    <textarea
                        placeholder="Please describe how do you see our collaboration - what are your requirements and expectations"
                        rows="5"
                        name="collabDescription"
                        class="collabDescription"
                        id="collabDescription"
                        formControlName="collabDescription"></textarea>
                    <mat-error *ngIf="collabDescription.touched && collabDescription.errors?.required">This field is
                        required</mat-error>
                </fieldset>
                <button class="tf-button btn-effect"
                    [disabled]="isSending">
                    <span class="boder-fade"></span>
                    <span [hidden]="isSending"><span class="effect">Send Message</span>
                    </span>
                    <img class="cg-loader"
                        [hidden]="!isSending"
                        src="/assets/loaders/loader-black.svg"
                        alt="loader" />
                </button>
            </form>
        </div>
    </div>
</div>
