<section class="cg-page-title" data-aos="fade-down" data-aos-duration="800">
    <!-- <h3>Virtual Console</h3> -->
</section>

<section
    class="tf-section tf-contact cg-virtual-console"
    data-aos="fade-down"
    data-aos-duration="800">
    <div class="container virtual-console-container">
        <button *ngIf="selectedGameUrl" class="full-screen-btn" (click)="triggerFullScreen()"><i class="fas fa-expand"></i> Full Screen</button>
        <button *ngIf="selectedGameUrl && isFullScreen" class="small-screen-btn" (click)="triggerFullScreen()" matTooltip="Close full screen" matTooltipClass="exit-fullscreen-tooltip">
          <i class="fas fa-times"></i>
        </button>
        <div class="virtual-console">
            <iframe *ngIf="selectedGameUrl" [src]="selectedGameUrl" frameborder="0" scrolling="no" [class.full-screen]="isFullScreen">
                <p>Your browser does not support iframes.</p> >
            </iframe>
            <div *ngIf="!selectedGameUrl" class="insert-cd-screen">
                <div class="cg-insert-header">COMPACT GAMES</div>
                <div class="cg-loader-container">
                    <div class="cg-loader-image"></div>
                </div>
                <div class="insert-disc">INSERT DISC</div>
            </div>
            <img *ngIf="!selectedGameUrl" src="assets/images/console-background.webp" class="console-background">
        </div>
        <div class="buttons-container">
            <!-- <button *ngIf="isWalletConnected" class="tf-button btn-effect select-game-btn" data-bs-toggle="modal" data-bs-target="#gameSelectionModal"> -->
            <button class="tf-button btn-effect select-game-btn" data-bs-toggle="modal" data-bs-target="#gameSelectionModal" (click)="logSelectGameClick()">
                <span class="boder-fade"></span>
                <span class="effect">
                    <fa-icon [icon]="faCompactDisc"></fa-icon> Select game <fa-icon [icon]="faCompactDisc" class="reverse-x"></fa-icon>
                </span>
            </button>
            <!-- <button *ngIf="!isWalletConnected" class="nav-item tf-button-st2 btn-effect" (click)="connect()">
                <span class="effect">Connect wallet</span>
            </button> -->
        </div>
    </div>
    <div class="mobile-only-info">
        Virtual console is currently not available on mobile devices
    </div>
</section>


<div class="modal fade popup" id="gameSelectionModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="cg-modal-content modal-content">
            <div class="header-popup">
                <!-- <h5>Select game</h5> -->
                <h5>Try out one of our demos</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body center">
                <div class="box-wallet-inner" *ngIf="games.length > 0">
                    <div *ngFor="let game of games" class="sc-box" (click)="selectGame(game)" data-bs-dismiss="modal">
                        <div class="img">
                            <img src="{{ game.imageUrl }}" alt="Image">
                        </div>
                        <p class="content">{{ game.title }}</p>
                    </div>
                </div>
                <div class="no-cds-message" *ngIf="games.length === 0">
                    There are no Compact Games NFTs in the connected wallet
                </div>
            </div>
        </div>
    </div>
</div>
