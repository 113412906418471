<section class="tf-section faq">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="tf-title m-b60" data-aos="fade-up" data-aos-duration="800">
          <p class="h8 sub-title">FAQs</p>
          <h4 class="title">Looking for answers?</h4>
        </div>
      </div>
      <div class="col-md-12">
        <div class="flat-accordion" data-aos="fade-up" data-aos-duration="800">
          <div *ngFor="let faqItem of faq" [class.active]="faqItem.isActive" class="flat-toggle box-text corner-box"
            (click)="logFaqClick(faqItem)">
            <div class="h7 toggle-title" [class.active]="faqItem.isActive">
              <div class="header-content">{{faqItem.question}}</div>
            </div>
            <p class="toggle-content" [innerHTML]="faqItem.answer"></p>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>