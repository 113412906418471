import { Component } from "@angular/core";
import { AppConstants } from "src/app/common/app-constants";
import { LoggingService } from "src/app/services/logging.service";

@Component({
  selector: 'cg-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent {

  public videoUrl: string = "https://www.youtube.com/embed/KuDwrTScFkc?si=CFYEFeYYFwK9ZV9E";
  public isOverviewVideoOpen: boolean;
  public twitterUrl: string = AppConstants.twitter;
  public discordUrl: string = AppConstants.discord;
  public tiktokUrl: string = AppConstants.tiktok;

  constructor(private loggingService: LoggingService) { }

  public playExhibitionVideo(el: HTMLMediaElement) {
    el.muted = true;
    el.play();
  }

  public triggerOverviewVideo(): void {
    this.isOverviewVideoOpen = !this.isOverviewVideoOpen;
    const eventName = this.isOverviewVideoOpen
      ? "Open overview video (hero section)"
      : "Close overview video (hero section)";
    this.loggingService.logEvent(eventName)
  }

  public logSocialMediaClick(socialMediaName: string): void {
    this.loggingService.logEvent("Go to social media link (hero section)", { name: socialMediaName })
  }
}
