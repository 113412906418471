<button class="tf-button btn-effect cg-btn"
  [class.smallBtn]="smallButton"
  [style.padding]="padding"
  [disabled]="disabled || isLoading"
  (click)="onButtonClick($event)">
  <span class="boder-fade"></span>
  <span [hidden]="isLoading">
    <span class="effect">
      <ng-content></ng-content>
    </span>
  </span>
  <img class="cg-loader"
    [hidden]="!isLoading"
    src="/assets/loaders/loader-black.svg"
    alt="loader" />
</button>
