import { Component, OnInit } from "@angular/core";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'cg-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  faQuestion = faQuestion;
  partners = [
    { image: "assets/images/partners-blurred/partner-1.webp" },
    { image: "assets/images/partners-blurred/partner-2.webp" },
    { image: "assets/images/partners-blurred/partner-3.webp" },
    { image: "assets/images/partners-blurred/partner-4.webp" },
    // { image: "assets/images/partners-blurred/partner-5.png" },
    { image: "assets/images/partners-blurred/partner-6.webp" },
    { image: "assets/images/partners-blurred/partner-7.webp" },
    { image: "assets/images/partners-blurred/partner-8.webp" },
    { image: "assets/images/partners-blurred/partner-9.webp" },
    { image: "assets/images/partners-blurred/partner-10.webp" },
    { image: "assets/images/partners-blurred/partner-11.webp" },
  //   { image: "assets/images/partners-blurred/partner-12.png" },
  //   { image: "assets/images/partners-blurred/partner-13.png" },
  //   { image: "assets/images/partners-blurred/partner-14.png" },
  //   { image: "assets/images/partners-blurred/partner-15.png" },
  //   { image: "assets/images/partners-blurred/partner-16.png" },
  //   { image: "assets/images/partners-blurred/partner-17.png" },
  ]

  featuredInGames = [
    {name: "Clone X", image: "assets/images/partners-blurred/partner-1.webp" },
    {name: "Crypto Dads", image: "assets/images/partners-blurred/partner-2.webp" },
    {name: "Otherside", image: "assets/images/partners-blurred/partner-3.webp" },
    {name: "Koin Games", image: "assets/images/partners-blurred/partner-4.webp" },
    {name: "Moonbirds", image: "assets/images/partners-blurred/partner-6.webp" },
    {name: "Moonrunners", image: "assets/images/partners-blurred/partner-7.webp" },
    {name: "CrypToadz", image: "assets/images/partners-blurred/partner-8.webp" },
    {name: "CryptoDickButts", image: "assets/images/partners-blurred/partner-9.webp" },
    {name: "Goblintown.wtf", image: "assets/images/partners-blurred/partner-10.webp" },
  ]

  constructor() { }

  ngOnInit(): void {
  }
}
