import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import Onboard from '@web3-onboard/core'
import injectedModule, { ProviderLabel } from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import { NetworkService } from './network.service';
import { ThemingMap } from '@web3-onboard/core/dist/types';
import { Network } from '../enums/network';
import { AppConstants } from '../common/app-constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Web3OnboardService {

  public connectedWallets$: Observable<any>;

  private onboard: any;

  constructor() {
    const injectedWallets = injectedModule({
      displayUnavailable: [
        ProviderLabel.MetaMask,
        ProviderLabel.Coinbase
      ],
      sort: (wallets) => {
        const metaMask = wallets.find(({ label }) => label === ProviderLabel.MetaMask)
        const coinbase = wallets.find(({ label }) => label === ProviderLabel.Coinbase)
        const deFiWallet = wallets.find(({ label }) => label === ProviderLabel.DeFiWallet)

        return (
          [
            metaMask,
            coinbase,
            deFiWallet,
            ...wallets.filter(
              ({ label }) => label !== ProviderLabel.MetaMask && label !== ProviderLabel.Coinbase && label !== ProviderLabel.DeFiWallet
            )
          ]
            // remove undefined values
            .filter((wallet) => wallet)
        )
      }
    });

    const walletConnect = walletConnectModule({
      // Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
      projectId: '312835a6fea0362d9a50134c0bd0ab98',
      // Chains required to be supported by all wallets connecting to your DApp
      requiredChains: [Network.AbstractTestnet],
      // requiredChains: [Network.Ethereum, Network.BinanceSmartChain, Network.Polygon, Network.Mumbai],
      /**
       * Defaults to `appMetadata.explore` that is supplied to the web3-onboard init
       * Strongly recommended to provide atleast one URL as it is required by some wallets (i.e. MetaMask)
       * To connect with WalletConnect
       */
      dappUrl: AppConstants.domainUrl
    });

    const customTheme: ThemingMap = {
      '--w3o-background-color': '#0b0d1a',
      '--w3o-foreground-color': '#0c1226',
      '--w3o-text-color': '#fff',
      '--w3o-border-color': '#434f7b',
      '--w3o-action-color': '#14c2a3',
      '--w3o-border-radius': '0px'
    }

    const network = NetworkService.getNetwork(environment.network);
    this.onboard = Onboard({
      wallets: [injectedWallets, walletConnect],
      chains: [{ id: network.id, token: network.nativeCurrency.symbol, label: network.displayName, rpcUrl: network.rpcUrls[0] }],
      theme: customTheme,
      appMetadata: {
        name: 'Compact Games NFT',
        icon: "assets/images/logo/logo-color.svg",
        logo: "assets/images/logo/logo-color.svg",
        description: 'Play, Collect, Compete: Enjoy Your NFTs like never before!',
        recommendedInjectedWallets: [
          { name: 'MetaMask', url: 'https://metamask.io' },
          { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
        ]
      },
      accountCenter: {
        desktop: {
          enabled: false
        },
        mobile: {
          enabled: false
        }
      },
      connect: {
        autoConnectLastWallet: true
      }
    });

    this.connectedWallets$ = this.onboard.state.select('wallets');
  }

  public async connectWallet(): Promise<any> {
    return await this.onboard.connectWallet();
  }

  public async disconnectWallet(): Promise<void> {
    const [primaryWallet] = this.onboard.state.get().wallets;
    await this.onboard.disconnectWallet({ label: primaryWallet.label })
  }
}
