import { Component, OnInit } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CompactGamesNftContractService } from "src/app/services/compact-games-nft-contract.service";

@Component({
  selector: 'cg-mint-page',
  templateUrl: './mint-page.component.html',
  styleUrls: ['./mint-page.component.scss']
})
export class MintPageComponent implements OnInit {

  public isMintOpen$: Observable<boolean>;

  constructor(private cgNftContractService: CompactGamesNftContractService) { }

  public ngOnInit(): void {
    this.isMintOpen$ = combineLatest([
      this.cgNftContractService.publicSaleOpen$,
      this.cgNftContractService.presaleOpen$
    ]).pipe(map(res => res[0] || res[1]));
  }
}
