<section class="tf-section tf-team">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-5 col-md-6 col-12">
                <div class="tf-title st2 m-b61"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <p class="h8 sub-title">Our team</p>
                    <h4 class="title">Meet our team</h4>
                </div>
                <div class="content mobie-30"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <div class="h7 txt-tranf-cap m-b16">
                        Who we are
                    </div>
                    <p>We are a couple passionate about all that is going on in the IT world! As IT professionals we've
                        been in
                        crypto since 2017 following the space and finally geting into NFTs in march 2021. We constantly
                        explore
                        new possibilites that non fungible tokens bring, trying to push boundaries and come with
                        innovative ideas
                        which realization will leave a mark in the history of crypto.</p>
                </div>
                <div class="button-fx m-t60"
                    data-aos="fade-in"
                    data-aos-duration="800">
                    <div class="btn-prev button-prev-team">
                        <span class="boder-fade"></span>
                        <span class="effect"><i class="far fa-chevron-left"></i></span>
                    </div>
                    <div class="btn-next button-next-team">
                        <i class="far fa-chevron-right"></i>
                    </div>
                </div>
            </div>

            <div class="col-xl-7 col-lg-7 col-md-6 col-12">
                <div class="wrap-team"
                    data-aos="fade-up"
                    data-aos-duration="800">
                    <swiper [config]="teamSliderConfig"
                        class="swiper-container sl-team">
                        <ng-template swiperSlide
                            *ngFor="let member of teamMembers">
                            <div class="team-box">
                                <div class="image">
                                    <img src="assets/images/common/team6.png"
                                        alt="">
                                </div>
                                <div class="content">
                                    <div class="h8">{{member.name}}</div>
                                    <p>{{member.description}}</p>
                                </div>
                            </div>
                        </ng-template>
                    </swiper>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="swiper-button-next btn-slide-next"></div>
            <div class="swiper-button-prev btn-slide-prev"></div>
        </div>
    </div>
</section>