<div class="container"
    data-aos="fade-right"
    data-aos-duration="800">
    <img src="assets/images/mint/mint-present.webp"
        class="present-image">
    <div class="form-container">
        <p>
            Welcome to the Compact Games NFT minting page! To
            ensure a fair distribution of our NFTs, we will be conducting a whitelist presale where users who meet
            certain criteria will have the opportunity to mint our NFTs before they are available to the general
            public.
            Our minting process has not yet started, but you can check your eligibility for our presale below.
        </p>
        <form class="form-contact"
            id="contactform"
            [formGroup]="whitelistCheckForm"
            (ngSubmit)="checkEligibility()">

            <fieldset>
                <div class="input-btn-set">
                    <input type="text"
                        name="address"
                        id="address"
                        placeholder="Wallet address"
                        formControlName="walletAddress">
                    <cg-button [smallButton]="true">Check</cg-button>
                </div>
                <mat-error *ngIf="walletAddress.touched && walletAddress.errors?.required">Enter wallet address in
                    order to check eligibility</mat-error>
            </fieldset>
            <h5 *ngIf="isWalletWhitelisted === false"
                class="eligibility-message">Entered wallet was not found on the whitelist</h5>
            <h5 *ngIf="isWalletWhitelisted === true"
                class="eligibility-message">Entered wallet is whitelisted</h5>
        </form>

        <p>
            Once the presale begins, eligible users will have the opportunity to mint a limited number of Compact
            Games
            NFTs at a given price. Our collection features a wide variety of CD games, each with its
            own unique design and rarity level.
        </p>
        <p>
            Thank you for your interest in the Compact Games NFT collection. We look forward to sharing these
            nostalgic
            gaming treasures with you through the exciting world of NFTs!
        </p>
    </div>
</div>
